import "./styles.css";

import { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { configs } from "../../constants";
import { tscLogoWithName } from "../../constants/images";
import {
  getAllOrganizationsOfCurrentUser,
  getCurrentMemberShip,
  getUserWithActiveOrganization,
  isEmailVerified,
} from "../../repositories/userRepository";
import { NEXT_STAGE } from "../../constants/globalConstants";
import { setUserLS } from "../../modules/localStorage";
import { useAuth } from "../../contexts";
import { setActiveOrganization } from "../../repositories/organizationRepository";
import SignUpWithXeroButton from "../SignUp/components/SignUpWithXeroButton";
import SubmitButton from "../SignUp/components/SubmitButton";

export default function Login() {
  const navigate = useNavigate();
  const location: any = useLocation();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  useEffect(() => {
    localStorage.clear();
  }, []);

  const login = async (e: any) => {
    e.preventDefault();
    const options = {
      headers: { "content-type": "application/json" },
    };
    axios.defaults.withCredentials = true;
    axios
      .post(
        configs.urls.BASE_URL + "/login",
        {
          username,
          password,
        },
        options
      )
      .then(
        async (res: AxiosResponse) => {
          const {
            response: { id = "", email = "", lastStage = "" },
          } = res.data;

          const user = (await getUserWithActiveOrganization(id)) || {};
          const {
            isDemo,
            id: orgId,
            organizationName,
            completedTutorial,
            createdAt,
            trialExpiryDate,
          } = user;

          setUserLS({
            id,
            email,
            lastStage,
            isDemo,
            organizationId: orgId,
            organizationName,
            completedTutorial,
            createdAt,
          });

          if (res.status === 401) {
            showNotification({
              color: "red",
              message: "User not found!",
              icon: <FontAwesomeIcon icon={faTimes} />,
              autoClose: 2000,
            });
          }

          if (!res.data.success) {
            showNotification({
              color: "red",
              message: "Wrong Credentials!",
              icon: <FontAwesomeIcon icon={faTimes} />,
              autoClose: 2000,
            });
            return;
          }
          localStorage.setItem("userId", id);
          localStorage.setItem("email", email);

          const dontShowDate = new Date("2024-06-03");
          const createdAtDate = new Date(createdAt);

          if (!trialExpiryDate && isDemo && createdAtDate >= dontShowDate) {
            localStorage.setItem("showUpgradeModal", "true");
          }

          const emailVerified = await isEmailVerified(id);

          if (!emailVerified) {
            window.location.href = "verify-email";
            return;
          }
          if (!lastStage) {
            window.location.href = "companies?tab=all";
            return;
          }

          const url = NEXT_STAGE[lastStage];
          if (url) {
            navigate(`/${url}`);
            return;
          }
          if (location.state?.from.pathname.includes("/accept_invitation")) {
            window.location.href = location.state?.from?.pathname;
            return;
          }

          if (orgId) {
            const { organizations } = await getAllOrganizationsOfCurrentUser();

            const { id } = await getUserWithActiveOrganization();

            await setActiveOrganization(id);

            const org =
              organizations.find(({ value }: any) => value == id) || {};
            const {
              isDemo = false,
              completedTutorial = false,
              createdAt,
            } = org;

            if (org) {
              const userMembership = await getCurrentMemberShip(id);
              localStorage.setItem("roleType", userMembership?.role?.type);
            }

            setUserLS({
              isDemo,
              completedTutorial: completedTutorial ? true : false,
              createdAt,
            });
            localStorage.setItem("organizationId", id);
          }

          if (isDemo) {
            window.location.href = "cashflow?tab=forecast";
          } else {
            window.location.href = "/get-started";
          }
        },
        (err) => {
          if (JSON.stringify(err).includes("401")) {
            showNotification({
              color: "red",
              message: `Cannot login, user not found! Please Signup first.`,
              icon: <FontAwesomeIcon icon={faTimes} />,
              autoClose: 2000,
            });
          } else {
            showNotification({
              color: "red",
              message: `${err}`,
              icon: <FontAwesomeIcon icon={faTimes} />,
              autoClose: 2000,
            });
          }
        }
      );
  };

  const handleSignInWithXero = async () => {
    try {
      const options = {
        headers: { "content-type": "application/json" },
      };
      axios.defaults.withCredentials = true;
      axios.post(configs.urls.BASE_URL + "/xero/signin", {}, options).then(
        async (res: AxiosResponse) => {
          if (res?.status === 200) {
            window.location.href = res?.data;
          }
        },
        (err) => {
          console.log("Sign up error: ", err);
        }
      );
    } catch (error) {
      console.error("Error signing up with Xero:", error);
    }
  };

  const handleForgetPassword = () => {
    navigate("/forget-password");
  };

  const [hasShadow, showShadow] = useState(false);

  return (
    <div
      className="card-outer-container"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <div
        onMouseOver={() => showShadow(true)}
        onMouseOut={() => showShadow(false)}
        className={`card-inner-container ${hasShadow ? "card" : ""}`}
        style={{ border: "1px solid #e6e6e6", borderRadius: "10px" }}
      >
        <div className="card-content">
          <div className="content">
            <div className="logo-container">
              <img src={tscLogoWithName} className="logo"></img>
            </div>
            <p className="already-have-account">
              Don't have an account? &nbsp;
              <Link to={{ pathname: "/signup" }} className="sign-in-link">
                Sign Up
              </Link>
            </p>
            <form onSubmit={login}>
              <div className="field">
                <label className="label">Company Email</label>
                <div className="control">
                  <input
                    required
                    className="input"
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={username}
                    onChange={(e) =>
                      setUsername(e.target.value?.toLocaleLowerCase())
                    }
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Password</label>
                <div className="control">
                  <input
                    required
                    className="input"
                    type="password"
                    name="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>

              {/* <button type="submit" className="button-image">
                <span>Sign In</span>
              </button> */}

              <div
                className="field"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <SubmitButton isSignIn={true} type="submit">
                  Sign In
                </SubmitButton>
              </div>
            </form>

            <div
              className="field"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "12px",
              }}
            >
              <span style={{ fontWeight: "700" }}>OR</span>
            </div>

            <div
              className="field"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <SignUpWithXeroButton onClick={handleSignInWithXero}>
                Sign In With Xero
              </SignUpWithXeroButton>
            </div>

            <p className="forget-password">
              Forget password &nbsp;
              <span
                onClick={handleForgetPassword}
                className="verify-email-link"
              >
                Click Here
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
